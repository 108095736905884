import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      avatarFile: null,
      avatarPreview: null,
      uploading: false,
    }
  },
  computed: {
    currentAvatarUrl() {
      if (this.userDataLocal) {
        if (this.userDataLocal.image) {
          return this.$http.defaults.baseURL + this.userData.image.contentUrl
        } return null
      }

      if (this.userData && this.userData.image) {
        return this.$http.defaults.baseURL + this.userData.image.contentUrl
      } return null
    },
  },
  methods: {
    avatarText,
    uploadAvatar(event) {
      // eslint-disable-next-line prefer-destructuring
      this.avatarFile = event.target.files[0]

      const reader = new FileReader()
      reader.onload = readerEvent => {
        this.avatarPreview = readerEvent.target.result
      }
      reader.readAsDataURL(this.avatarFile)

      const formdata = new FormData()
      formdata.append('file', this.avatarFile)

      this.uploading = true
      this.$http.post('media_objects', formdata).then(response => {
        if (response && response.status === 201) {
          if (this.userDataLocal) {
            this.userDataLocal.image = response.data['@id']
          } else {
            this.userData.image = response.data['@id']
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nie udało się ustawić zdjęcia.',
              text: response ? response.data['hydra:description'] : null,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }).finally(() => {
        this.uploading = false
      })
    },

    clearAvatar() {
      this.$refs.refInputEl.value = null
      this.avatarPreview = null
      if (this.userDataLocal) {
        this.userDataLocal.image = null
      } else {
        this.userData.image = null
      }
    },
  },

}
