<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template
          #button-content
        >
          <div class="d-sm-flex d-none user-nav">
            <template v-if="userData">
              <p class="user-name font-weight-bolder mb-0">
                {{ userData.firstName }} {{ userData.lastName }}
              </p>
              <span class="user-status">{{ userData.roles | userRole }}</span>
            </template>
            <template
              v-else
            >
              <p class="user-name font-weight-bolder mb-0">
                &bull; &bull; &bull;
              </p>
            </template>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :src="currentAvatarUrl"
            :text="userData ? avatarText(userData.lastName + ' ' + userData.firstName) : null"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          to="/account"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Wyloguj</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import AvatarMixin from '@/mixins/avatarMixin'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components

  },
  mixins: [AvatarMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    },
  },
  beforeMount() {
    if (!this.userData) {
      this.$store.dispatch('auth/fetchUserData')
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.replace('/login')
    },
  },
}
</script>
