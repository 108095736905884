<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="visibleMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },

  computed: {
    visibleMenuItems() {
      return this.navMenuItems.filter(item => {
        if (!item.roles) return true
        if (item.roles && !this.currentUser) return false
        return item.roles.some(role => this.currentUser.roles.includes(role))
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
