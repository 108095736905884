export default [
  {
    title: 'Pulpit',
    // route: 'dashboard',
    route: 'home',
    roles: ['ROLE_EMPLOYEE', 'ROLE_TRAINER', 'ROLE_MANAGER'],
    icon: 'HomeIcon',
  },
  {
    title: 'Pracownicy',
    route: 'users',
    roles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_FORD_POLAND'],
    icon: 'UserIcon',
  },
  {
    title: 'Dealerzy',
    route: 'dealers',
    roles: ['ROLE_ADMIN', 'ROLE_FORD_POLAND'],
    icon: 'TruckIcon',
  },
  {
    title: 'Szkolenia',
    // route: 'trainings',
    route: 'training-courses',
    icon: 'BookIcon',
  },
  {
    title: 'Kalendarz',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
]
